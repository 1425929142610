
.relativeContentLayout {
  & > [exiting="next"] {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
  }
  & > [exiting="previous"] {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
  }
}

.fadeSlideCrossTransitionView {
  & > * {transition: all .5s; }

  & > [entering="next-start"] {
    opacity: 0;
    transform: translate(30px);
  }
  & > [entering="previous-start"] {
    opacity: 0;
    transform: translate(-30px);
  }
  & > [entering="end"] {
    opacity: 1;
    transform: translate(0);
  }
  & > [exiting="next"] {
    opacity: 0;
    transform: translate(30px);
  }
  & > [exiting="previous"] {
    opacity: 0;
    transform: translate(-30px);
  }
}

.crossFadeCrossTransitionView {
  & > * {transition: opacity .5s;}

  & > [entering="next-start"] {
    opacity: 0;
  }
  & > [entering="previous-start"] {
    opacity: 0;
  }
  & > [entering="end"] {
    opacity: 1;
  }
  & > [exiting="next"] {
    opacity: 0;
  }
  & > [exiting="previous"] {
    opacity: 0;
  }
}


.slideCrossTransitionView {
  & > * {transition: transform .5s;}

  & > [entering="next-start"] {
    transform: translateX(100%);
  }
  & > [entering="previous-start"] {
    transform: translateX(-100%);
  }
  & > [entering="end"] {
    transform: translateX(0);
  }
  & > [exiting="next"] {
    transform: translateX(100%);
  }
  & > [exiting="previous"] {
    transform: translateX(-100%);
  }
}