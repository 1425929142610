subtle-fade-slide-dramatic-view {
  .contentWrap {
    transform: translateY(-8%);
    opacity: 0;
    transition: all 1.3s $ease;
  }
  .backgroundOverlay {
    opacity: 0;
    transition: opacity 3s;
  }

  &.ENTERING {
    .contentWrap {
      transform: translateY(0%);
      opacity: 1;
    }
    .backgroundOverlay {
      opacity: .5
    }
  }
  &.EXITING {
    transition-duration: 3s;
    pointer-events: none;

    .contentWrap {
      transform: translateY(-12%);
      transition: all .4s ease-in;
    }
    .backgroundOverlay {
      opacity: 0
    }
  }
}