button-stack-switch {
  .btn {
    transition: .8s ease;
    transition-property: background-color, font-weight, color;

    &:active {
      color: white;
      background: $clBrandSecondary;
      transition-duration: .1s;
    }
  }

  > * {
    &:not(:last-child) {
      margin-bottom: len(2);
    }
  }
}