social-button {
  border-bottom: len(.3) solid transparentize(secondary(0), 0.5);
  > * {
    margin-bottom: len(-0.2);
  }

  &.circleBackground {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: -60%;
      left: -40%;
      width: 150%;
      height: 165%;

      background: white;
      border-bottom-right-radius: 100%;
      opacity: 0.075;
      transition: all 0.3s ease;
    }

    &:hover {
      &::after {
        transform: scale(1.1);
        opacity: 0.15;
      }
    }
  }
}