.textBtn {
  .spreaderBox {
    transform: scale(.8,.95);
    transition: all .7s;
  }
  &:hover .spreaderBox {
    opacity: .05;
    transform: scale(1);
    transition-duration: .2s;
  }
  &:active .spreaderBox {
    opacity: .2;
    transform: scale(1.1);
    transition-duration: .1s;
  }
}