.portfolioHistoryFormItem {
  &.notFirst {
    padding-top: len(3);
    margin-top: len(1);
  }
}


.theHireBtn {
  transform: translateX(100%);
  transition: transform .6s ease;

  &.ENTERING {
    transform: translateX(0%);
    transition: transform 1.2s ease .5s;
  }


  .theInsetShade {box-shadow: inset 0px -3px rgba(0,0,0,.2)}
  .triangleArrow {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    border-top: solid transparent len(.6);
    border-left: solid white len(.5);
    border-bottom: solid transparent len(.6);
  }
}

.customTopShadowForTheGetDiscoveredSection {
  box-shadow: 0px 0px 8px #0000004a;

  //box-shadow:
  //  0 0 len(.9) len(.2) transparentize(secondary(0), .5),
  //  0 0 70px hsla(0, 0%, 0%, 0.55);
}

.userProfileMap {
  height: len(25);
}