fade-slide-dramatic-view {
  transform: translateY(15%);
  opacity: 0;

  &.ENTERING {
    transform: translateY(0%);
    opacity: 1;
    transition: all 1s $ease;
  }
  &.EXITING {
    transform: translateY(-15%);
    transition: all .3s ease-in;
    pointer-events: none;
  }
  .onlySlideOut.ENTERING {
    transition: none;
  }
}