@function lenRound($size) {
  @return $size * $unit;
  //todo: decide what to do with this dumb thing
  //$possible: $size * $unitSize; //5
  //@if($possible % 2 > 0) { // true
  //  @return ($possible + 1) / $unitSize * $unit;
  //} @else {
  //  @return $possible / $unitSize * $unit;
  //}
}

@function len($size) {
  @return $size * $unit;
}

@function fontSize($key) {
  @return len(map-get($fontSizes, $key));
}

@function letterSpacing($key) {
  @return len(map-get($letterSpacings, $key));
}

@function borderRadius($key) {
  @return len(map-get($borderRadius, $key));
}


@function contains($list, $item) {
  @if index($list, $item) {
    @return true;
  }
  @return false;
}


//assign the sign from source to destination
@function sign($source, $dest) {
  @if $source >= 0 {
    @return abs($dest);
  } @else {
    @return abs($dest) * -1;
  }
}


@function getOrFail($theMap, $key) {
  @if contains(map-keys($theMap), $key) {
    @return map_get($theMap, $key);
  } @else {
    @error ('no such thing: #{$key} from map #{$theMap}') ;
  }
}

@function gradients ($key) {
  @return getOrFail($gradients, $key);
}


@function primary ($key) {
  @return getOrFail($primaryPalette, $key);
}

@function secondary ($key) {
  @return getOrFail($secondaryPalette, $key);
}

@function neutral($key) {
  @return getOrFail($neutralPalette, $key);
}