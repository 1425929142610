select-search-input {
  input[type=checkbox]:checked ~ dropdown-wrapper,
  input[type=checkbox] ~ dropdown-wrapper:active {
    max-height: len(40);
    dropdown-body {
      max-height: len(30);
      overflow-y: auto;
    }
  }
  input[type=checkbox] + div > svg {
    transition: transform 0.2s ease;
    transform: rotate(0);
  }
  input[type=checkbox]:checked + div > svg {
    transform: rotate(180deg);
  }
}