
wizard-view {
  main-wizard-wrap {
    transition: transform .9s cubic-bezier(0.69, 0.51, 0.13, 1);

    &.moveAwayLeft {
      transition: transform 1200ms cubic-bezier(0.82, 0.04, 0.29, 0.89)
    }
  }

  main-wizard-shade {
    transition:
      transform 1.2s cubic-bezier(0.69, 0.51, 0.13, 1),
      opacity .4s linear;

    &.moveAwayLeft {
      transition: transform 1.6s cubic-bezier(0.76, 0, 0.29, 0.99);
    }
  }
}