$clBrandPrimary: hsl(218, 22%, 7%);
$clBrandSecondary: hsl(148, 78%, 70%);
$clBrandTextLink: hsl(313, 78%, 70%);

$backgroundGrey: hsl(210, 25%, 97%);
$backgroundBlack: hsl(220, 15%, 16%);
$backgroundWhite: hsl(360, 100%, 100%);

$notificationRed: #ff39d0;
$notificationOrange: #e8a839;
$notificationBlue: #3783e5;
$notificationGreen: #76eeae;
$notificationBackgroundPink: #ffaae6;

$btnTransparentBack : hsla(0, 0%, 100%, 0.5);
$btnShadow: hsla(213, 9%, 19%, 0.1);
$specialSearchBarShadowColor:  hsla(213, 9%, 19%, 0.13);
$emptyResultShadow :hsla(0, 0%, 41%, 0.05);
$sliderThumbShadow : hsla(213, 9%, 19%, 0.25);
$backBtnBackground : hsla(218, 22%, 7%, 0.1);
$backBtnBackground2 : hsla(0, 0%, 100%, 0.2);
$wrapperShadow : hsla(0, 0%, 0%, 0.1);

$unitSize1: 6.5;
$unitSize2: 8;
$unitSize3: 10;
$unitSize4: 12;
$fontFamily: sans-serif;
$fontSizes: (-2:0.8, -1:0.9, 0:1.0, '0h':1.2, 1:1.4, 2:1.6, 3:2, 4:2.4, 5:2.5, 6:6);
$fontWeights: (300, 400, 500, 700);
$letterSpacings: (0:0, 1:0.025, 2:-0.01);
$borderRadius: (0:.5, 1:10, 2:1, 3:.25, 4:12.5, 5:3, 6:3.5, 7:2, 8:.8, 9:1.5, 17:1.7, 25:2.5, 28:2.8);
$ease: cubic-bezier(0.270, 0.300, 0.000, 1.000);
$phoneWidthUnits: 54;
$phoneWidthUnits: 54;
$desktopWidthUnits: 130;
$biggerThanDesktopWidthUnits : 145;
$betweenPhoneDesktopWidthUnits : 88;

$neutralPalette: (
  0:  hsl(0, 0%, 100%),
  10: hsl(210, 25%, 94%),
  20: hsl(210, 15%, 84%),
  40: hsl(212, 9%, 66%),
  60: hsl(212, 7%, 57%),
  80: hsl(212, 6%, 48%),
  100: hsl(212, 33%, 76%),
);

$primaryPalette: ( 
  0: $clBrandPrimary,
  10:hsl(240, 2%, 91%),
  20:hsl(240, 1%, 81%),
  40:hsl(220, 2%, 63%),
  60:hsl(216, 2%, 44%),
  80:hsl(214, 5%, 26%),
  100:hsla(218, 22%, 7%,0.8),
);

$secondaryPalette: (
  0:$clBrandSecondary,
  10:hsl(150, 75%, 97%),
  20:hsl(147, 80%, 94%),
  40:hsl(149, 77%, 88%),
  60:hsl(147, 78%, 82%),
  80:hsl(148, 77%, 76%),
  100:$clBrandTextLink,
  110:hsl(148, 57%, 63%),
  200 :hsla(240, 9%, 96%, 1),
  310:hsl(295, 38%, 94%),
  320:hsl(308, 50%, 91%),
  360:hsl(312, 72%, 81%),
  400:hsl(214, 77%, 56%),  //  Feedback Default
  410:hsl(214, 58%, 95%),  //  Feedback Default 10
  500:hsl(131, 43%, 61%),  //  Feedback Success Default
  510:hsl(135, 36%, 96%),  //  Feedback Success 10
  600:hsl(38, 79%, 57%,),  //  Feedback Warning Default 
  610:hsl(43, 78%, 96%),   //  Feedback Warning 10 
  700:hsl(16, 74%, 58%),   //  Feedback Error Default 
  710:hsl(10, 60%, 96%),   //  Feedback Error 10 
  800: $backgroundGrey,      //  Background Grey
  810: $backgroundBlack,     //  Background Black
);


$gradients: (
  10:linear-gradient(135.14deg, hsl(148, 86%, 71%) 11.49%, hsl(230, 77%, 69%) 50.78%, #EE76D4 89.27%),
  20:linear-gradient(89.87deg, #75F5B1 6.23%, #7488ED 51.91%, #EE76D4 96.65%),
  300:linear-gradient(180deg, hsl(214, 77%, 56%) 0%, hsl(214, 67%, 52%) 100%),          //  Feedback Default
  400:linear-gradient(180deg, hsl(131, 43%, 61%) 0%, hsl(129, 37%, 56%) 100%),          //  Feedback Success Default
  500:linear-gradient(180deg, hsl(16, 74%, 58%) 0%, hsl(15, 67%, 58%) 100%),            //  Feedback Error Default 
  520:linear-gradient(180deg, hsla(16, 74%, 58%,0.1) 0%, hsla(15, 67%, 58%,0.1) 100%),  
  600: linear-gradient(180deg, hsla(240, 20%, 98%, 0) 0%, hsla(240, 20%, 98%, 1) 100%),       
);
