pika-boo-dramatic-view {
  &.ENTERING {
    .clipper {
      transform: translateY(0%);
    }
    .bgOverlay.shown {
      opacity: .3;
    }
  }
  &.EXITING {
    transition-duration: 2s;
    pointer-events: none;

    .clipper {
      transition-duration: .4s;
      transition-timing-function: ease-in;
    }
    .bgOverlay {
      transition-duration: 2s;
    }
  }

  .bgOverlay {
    opacity: 0;
    transition: opacity 3s;
  }

  .clipper {
    transform: translateY(100%);
    transition: transform 1s $ease;
  }
}