slide-up-dramatic-view {

  &.ENTERING {
    .contentViewport {
      transform: translateY(0%);
    }

    .bgOverlay {
      opacity: .3;
    }
  }

  &.EXITING {
    transition-duration: .4s;
    pointer-events: none;

    .contentViewport {
      transition-duration: .4s;
      transition-timing-function: ease-in;
    }

    .bgOverlay {
      transition-duration: .4s;
    }
  }

  .bgOverlay {
    opacity: 0;
    transition: opacity 1s;
  }

  .contentViewport {
    transform: translateY(100%);
    transition: transform 1s ease;
  }
}

slide-up-partial-dramatic-view {

  &.ENTERING, &.ENTERING.isDelayedEntrance {
    & > * {
      transform: translateY(0%);
    }
  }

  &.EXITING {
    transition-duration: .9s;
    pointer-events: none;

    & > * {
      transition-duration: .4s;
      transition-timing-function: ease-in;
    }

    &.isDelayedEntrance > * {
      transform: translateY(300%); //because of the levitating button click ripple
      transition: transform .5s ease-in .4s;
    }
  }

  & > * {
    transform: translateY(100%);
    transition: transform 1s ease;
  }
  &.isDelayedEntrance > *{
    transform: translateY(100%);
    transition: transform 1.6s cubic-bezier(0.15, 1.41, 0.49, 1) 1.4s;
  }
}
