nav-bar-bottom {
  $shadeLength: len(0.5);

  // &.attached {
  //   nav-bar-body {
  //   }
  //   .shade {
  //     padding-bottom: constant(safe-area-inset-bottom);
  //     padding-bottom: env(safe-area-inset-bottom);
  //   }
  // }

  .midItem {
    min-width: 9rem;
    min-height: 9rem;
    margin-top: -1rem;
    flex: 1.8;
    filter: drop-shadow(0px 3px 8px hsla(0, 0%, 0%, 0.2));
    svg:not(.selectLayer) {
      @extend .absoluteCenter, .w12, .h12q, .noPointerEvents;
      top: 53%;
    }
    .layerContainer {
      @extend .absoluteCenter, .w10, .h10, .noPointerEvents;
    }
    .selectLayer {
      @extend .absolute, .noPointerEvents;
      width: 9.75rem;
      height: 9.75rem;
      transform: scale(0.8);
      left: 0.125rem;
      top: 0.125rem;
      transition: 0.2s ease-in-out transform;
    }
    .showLayer {
      transform: scale(1);
    }
  }
  .notificationIcon.showIndicator.notActive svg {
    :first-child {
      fill: #ffaae6;
      stroke: white;
    }
    :nth-child(2) {
      stroke: white;
      fill: #ff39d0;
    }
  }

  .notificationIcon.showIndicator svg {
    :first-child {
      fill: $notificationBackgroundPink;
    }
    :nth-child(2) {
      stroke: $backgroundBlack;
      fill: $notificationRed;
    }
  }


  .moreWithIndicatorSpecial svg {
    :nth-child(4) {
      fill: lightgreen;
    }
  }
  // &.ed {
  //   nav-bar-body {
  //     max-width: len(45);

  //     .navBarShade {
  //       box-shadow:
  //         0 0 3.5rem rgba(0, 0, 0, 0.1),
  //         0 2rem 1.5rem -1rem rgba(0, 0, 0, 0.2);
  //     }
  //   }
  // }
}
