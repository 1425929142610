.twoPartDramaticView {

  &.ENTERING {
    .transitionOverlay {opacity: 1}
    .top { transform: translateY(0%) }
    .bottom { transform: translateY(0vh) }
  }


  &.EXITING {
    pointer-events: none;
    transition-duration: .7s;

    .top, .bottom, .dynamicBackground {
      transition-timing-function: ease-in;
      transition-duration: .3s;
    }
    .bottom {
      transform: translateY(110%);
      transition-duration: .3s;
    }
  }



  .transitionOverlay {
    background-color: transparentize(#193341, 0.1);
    opacity: 0;
    transition: opacity .7s;
  }

  .contentWrap {

  }
  .top {
    transform: translateY(-100%) scaleY(.7);
    transition: all .6s;
  }

  //good times
  //.top {
  //  transform: rotateX(60deg);
  //  transform-origin: 50% 100% -300px;
  //  transition: all .6s;
  //}
  .bottom {
    transform: translateY(100vh);
    transition: all .8s $ease;
  }
}

