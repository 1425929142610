#splash {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0; right: 0; bottom: 0; left: 0;
  text-align: center;
  color: white;
  background-color: white;
  opacity: 1;
  z-index: 9999;
  transition: opacity 1s ease-out, transform 1s ease-in;

  &.leaving {
    opacity: 0;
    transform: scale(1.3);
    pointer-events: none;
  }
  .wall {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #263238;
    opacity: 0;
    animation: 3s fadein;
    animation-fill-mode: forwards;

    & > svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .content {
    position: relative;
    animation: 10s grow ease-out;
    animation-fill-mode: forwards;
    z-index: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}
@keyframes grow {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}