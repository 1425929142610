.tabView {
  padding-bottom: len(.8);

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: len(.2);
    background-color: $clBrandSecondary;
    opacity: 0;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
    transition-property: transform, opacity;
  }

  &.isSelected {
    &::before {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}