table.gridMenu {
  border-collapse: separate;
  border-spacing: len(1);

  .menuCell {
    .backgroundColor {
      opacity: 0;
      transform: scale(.8);
      transition: all 1s;
    }
    &:active .backgroundColor {
      opacity: .5;
      transform: scale(1);
      transition-duration: .1s;
    }
  }
}
