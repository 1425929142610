window-dramatic-view-old {
  transform: translate3d(0, calc(100% + #{lenRound(10)}), 0);
  transition: transform 1s cubic-bezier(0.1, 1.3, 0, 1);

  &.ENTERING {
    transform: translate3d(0, 0%, 0);
  }
  &.EXITING {
    transform: translate3d(0, -100vh, 0);
    transition-duration: .18s;
    transition-timing-function: ease-in;
    pointer-events: none;
  }

  .backgroundWrap {
    box-shadow: 0 0px 11px 4px transparentize(primary(10), .8);
  }
  .exitClickOverlay {
    height: 100vh;

    &.topOverlay {
      bottom: 100%;
    }
  }
}