//linear-gradient(180deg, hsla(240, 20%, 98%, 0) 0%, hsla(240, 20%, 98%, 1) 100%),

.headerBackgroundOverlayGradient {
  background: linear-gradient(
    90deg,
    primary(0) 0%,
    transparentize(primary(0), .8) 50%,
    //transparentize(primary(0), .8) 20%,
    //transparentize(primary(0), .8) 80%,
    primary(0) 100%
  );
}