$unitSize1: 10 !default;
$unitSize2: 12 !default;
$unitSize3: 14 !default;
$unitSize4: 16 !default;

$unit: 1rem;
$fontFamily: "Ubuntu", "Open Sans", sans-serif !default;
$fontSizes: (-20:0.8, -10:0.9, 0:1.0, 10:1.4, 20:1.6, 30:2, 40:2.4) !default;
$fontWeights: (300, 400, 500, 700) !default;
$letterSpacings: (0:0, 10:0.2) !default;
$borderRadius: (0:.5, 10:1) !default;
$primaryPalette: (0: hsl(22, 88%, 56%), 10: hsl(33, 89%, 57%)) !default;
$secondaryPalette: (0: hsl(200, 100%, 39%), 10: hsl(200, 97%, 10%)) !default;
$tertiaryPalette: () !default;
$neutralPalette: (0: hsl(198, 16%, 84%), 10: hsl(204, 15%, 94%)) !default;
$gradients: () !default;
$phoneWidthUnits: 35 !default;
$ease: cubic-bezier(0.270, 0.300, 0.000, 1.000) !default;

$clRed: #DB4437;
$clGreen: #20B05F;
$clTwitter: #00A2F9;
$clFacebook: #3B5998;
$clGoogle: #EA4335;
$clLinkedin: #0084BF;


@import "~normalize-css/normalize";
@import "reset-button";
@import "functions";
@import "mixins";
@import "base";
@import "helpers";
@import "animations";





