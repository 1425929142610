google-map-wrap {
  .aCluster {
    position: relative;
    width: len(3) !important;
    height: len(3) !important;
    text-align: center;
    font-size: fontSize(1) !important;
    font-weight: bold;
    line-height: len(3) !important;
    font-family: inherit !important;
    color: white !important;
    border-radius: 50%;
    background: primary(10);
    box-shadow: 0 1px len(.8) rgba(0, 0, 0, 0.2);
    transform: translate(-50%, -50%);
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 300%;
      height: 300%;
      border-radius: 50%;
      background: radial-gradient(#{transparentize(primary(10), 1)}, #{transparentize(primary(10), .6)});
      transform: translate(-50%, -50%) scale(.55);
      transition: transform 1s ease-in;
    }
    &:active::after {
      transform: translate(-50%, -50%) scale(1);
      transition-duration: .1s;
      transition-timing-function: ease-out;
    }
  }
}