
$distance: 5%;

[ef-drama] {
  transition:
    transform 1.1s cubic-bezier(.31,.2,0,1),
    opacity .3s linear;
}
[ef-drama^=leave] {
  pointer-events: none;
}

[ef-drama='enter-from-top-start'], [ef-drama='leave-from-bottom'] { //adds `-start`
  opacity: 0;
  transform: translateY(-$distance);
}
[ef-drama='enter-from-top'], [ef-drama='enter-from-bottom'] {
  opacity: 1;
  transform: translateY(0);
}
[ef-drama='leave-from-top'], [ef-drama='enter-from-bottom-start'] {
  opacity: 0;
  transform: translateY($distance);
}

[ef-drama='enter-from-left-start'], [ef-drama='leave-from-right'] { //adds `-start`
  opacity: 0;
  transform: translateX(-$distance);
}
[ef-drama='enter-from-left'], [ef-drama='enter-from-right'] {
  opacity: 1;
  transform: translateX(0);
}
[ef-drama='leave-from-left'], [ef-drama='enter-from-right-start'] {
  opacity: 0;
  transform: translateX($distance);
}


