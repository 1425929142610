slider {
  $handleWidth: len(2.4);
  padding-left: #{$handleWidth/2};
  padding-right: #{$handleWidth/2};


  .handle {
    transform: translate3d(-50%, -50%, 0);
    //box-shadow: 0 0 11px 4px transparentize(gray(0), .7);
    //border: len(.2) solid white;
    width: $handleWidth;

    &::before {
      content: "";
      display: block;
      margin-top: 100%;
    }

  }
  .handleText {
    transform: translateX(-50%);
  }

}
