slider-horizontal-gpu {

  .viewsWrap {
    transition: transform 1.7s cubic-bezier(0.130, 1.20, 0.000, 1.000);
  }

  .slidedView {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
  }
}