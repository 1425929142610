rating {
  > :nth-child(2) {
    &.ratingStar {
      margin-left: 0;
    }
  }
  > :last-child {
    &.ratingStar {
      margin-right: 0;
    }
  }
  .ratingStar {
    margin: 0 len(.25);
  }
}