.pulseFadeAnimation {
  animation: 2s ease infinite pulseFade;
}

@keyframes pulseFade {
  0% {opacity: 1; transform: scale(1);}
  50% {opacity: 0.2; transform: scale(.8);}
  100% {opacity: 1; transform: scale(1);}
}

.pulseOutwradAnimation {
  animation: 2s ease infinite pulseOutwrad;
}

@keyframes pulseOutwrad {
  0% {opacity: 1; transform: scale(1);}
  10% {opacity: 1; }
  100% {opacity: 0; transform: scale(2);}
}


.fadeInAnimation {
  animation: .6s ease-out 0s fadeInKeyframes;
}
@keyframes fadeInKeyframes {
  from { opacity: 0; }
  to { opacity: 1; }
}

.markerBounceAnimation {
  transform-origin: bottom;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.22, 2, 0.030, 1.000);
  animation-name: markerBounceKeyframes
}
@keyframes markerBounceKeyframes {
  0%   { opacity: 0; transform: scale(1, 1.3) translateY(-15%); }
  100% { opacity: 1; transform: scale(1, 1)  translateY(0%); }
}

