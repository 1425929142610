html,
body {
  background-color: $clBrandPrimary;
}

.heroBold {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: bold;
  font-size: len(6);
  line-height: 125%;
}
.heading1Bold {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: bold;
  font-size: len(4);
  line-height: 124%;
}
.heading2Bold {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: bold;
  font-size: len(3);
  line-height: 4rem;
}
.heading3Bold {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: bold;
  font-size: len(2.25);
  line-height: 132%;
}
.heading4Bold {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: bold;
  font-size: len(2);
  line-height: 150%;
}
.heading5Bold {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: bold;
  font-size: len(1.75);
  line-height: 3rem;
}
.heading6Bold {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: bold;
  font-size: len(1.5);
  line-height: 130%;
}
.bodyBold {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: bold;
  font-size: len(1.75);
  line-height: len(3);
}
.notesBold {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: bold;
  font-size: len(1);
  line-height: 2rem;
}
.heroRegular {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: len(6);
  line-height: 125%;
}
.heading1Regular {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: len(4);
  line-height: 117%;
}
.heading2Regular {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: len(3);
  line-height: 124%;
}
.heading3Regular {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: len(2.25);
  line-height: 132%;
}
.heading4Regular {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: len(2);
  line-height: 150%;
}
.heading5Regular {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: len(1.75);
  line-height: 170%;
}
.heading6Regular {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: len(1.5);
  line-height: 130%;
}
.bodyRegular {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: len(1.75);
  line-height: len(3);
}
.notesRegular {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: len(1);
  line-height: 2rem;
}

.heroLight {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 300;
  font-size: len(6);
  line-height: 125%;
}
.heading1Light {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 300;
  font-size: len(4);
  line-height: 117%;
}
.heading2Light {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 300;
  font-size: len(3);
  line-height: 124%;
}
.heading3Light {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 300;
  font-size: len(2.25);
  line-height: 132%;
}
.heading4Light {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 300;
  font-size: len(2);
  line-height: 150%;
}
.heading5Light {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 300;
  font-size: len(1.75);
  line-height: 170%;
}
.heading6Light {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 300;
  font-size: len(1.5);
  line-height: 130%;
}
.bodyLight {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 300;
  font-size: len(1.75);
  line-height: len(3);
}
.notesLight {
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 300;
  font-size: len(1);
  line-height: 156%;
}

.btnShadow {
  filter: drop-shadow(0 1rem 2rem $btnShadow);
}
.btnShadowNone {
  filter: none;
}
.thumbShadow {
  box-shadow: 0 0 0.25rem $sliderThumbShadow;
}
.backgroundBlack {
  background-color: $backgroundBlack;
}
.backgroundGrey {
  background-color: $backgroundGrey;
}
.backgroundWhite {
  background-color: $backgroundWhite;
}
.tagPad {
  padding: 0.75rem 1.75rem 0.75rem 0.75rem;
}

.hairLinePad {
  padding: 0.25rem;
}

.hairLinePadTop {
  padding-top: 0.5rem;
}

.pad125 {
  padding: 1.25rem;
}

.miniTagPad {
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
}
.smallTagPad {
  padding: 0.75rem 1.75rem 0.75rem 0.75rem;
}
.smallTagLowPad {
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
}
.smallTextTagPad {
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
}
.BigTagPad {
  padding: 0.75rem 1.75rem 0.75rem 0.75rem;
}
.hairLineBorderWidth {
  border-width: 0.25rem;
}
.hairLineHeight {
  height: 0.25rem;
}
.hairLineWidth {
  width: 0.125rem;
}
.trans3 {
  transition: 0.3s ease;
}
.borderW125 {
  border-width: 0.125rem;
}
.emptyCardShadow {
  box-shadow: 0 1rem 2rem $btnShadow;
}
.emptyResultShadow {
  box-shadow: 1rem 1rem 4rem $emptyResultShadow;
}

.borderBottomW125 {
  border-bottom-width: 0.125rem;
}
.borderTopW125 {
  border-top-width: 0.125rem;
}
.borderBottomW25 {
  border-bottom-width: 0.25rem;
}
.borderBottomSolid {
  border-bottom-style: solid;
}
.borderTopSolid {
  border-top-style: solid;
}

.padLeft1hq {
  padding-left: 1.75rem;
}
.padRight1hq {
  padding-right: 1.75rem;
}

.widthMinContent {
  width: min-content;
}

.lineH120 {
  line-height: 1.2;
}

.specialSearchShadow {
  box-shadow: 0 0 2rem $specialSearchBarShadowColor;
}

.radius18 {
  border-radius: 1.8rem;
}

.w75 {
  width: 0.75rem;
  min-width: 0.75rem;
}

.h75 {
  height: 0.75rem;
  min-height: 0.75rem;
}
.backBtnBackground {
  background-color: $backBtnBackground2;
}

.top53 {
  top: 53%;
}

.smallCircleWidth {
  width: 0.625rem;
}
.smallCircleHeight {
  height: 0.625rem;
}

.marRight0q {
  margin-right: 0.25rem;
}

.optionLargeSelectPad {
  padding: 1.75rem 2.25rem 2.75rem;
}
.playerRowSelectPad {
  padding: 1.25rem 1.75rem 1.25rem;
}

input:-internal-autofill-selected {
  background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.hairLineHeight1 {
  height: 0.125rem;
}

.o85 {
  opacity: 0.85;
}

.backgroundTop {
  background-position: top;
}

.newWrapperShadow {
  filter: drop-shadow(0 0 2rem $wrapperShadow);
}

.block {
  display: block;
}

.o015 {
  opacity: 0.15;
}

.transBlur {
  backdrop-filter: blur(1rem);
  background-color: $btnTransparentBack;
}

.moveAwayBottom2 {
  transform: translateY(120%);
}

.pPadBottom1 {
  padding-bottom: 1rem !important;
}

.specialAspectRatio {
  aspect-ratio: 16/10;
}

.scrollingSlide {
  width: 100vw;
  padding: 0 2rem;
  @extend .maxWidthPhone;
}

//todo: @viktor: move to framework
.lineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.backgroundRed {
  background-color:$notificationRed !important;
}

.backgroundGreen {
  background-color:$notificationGreen !important;
}

.backgroundOrange {
  background-color:$notificationOrange !important;
}

.backgroundBlue {
  background-color:$notificationBlue !important;
}

.horizontalHairlineMargin {
  margin-left: 1.3rem;
  margin-right: 1.3rem;
}

.negativeHorizontalHairlineMargin {
  margin-left: -1.3rem;
  margin-right: -1.3rem;
}