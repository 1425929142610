fade-dramatic-view {
  opacity: 0;
  transition: opacity 1.4s;

  &.ENTERING {
    opacity: 1;
  }
  &.EXITING {
    pointer-events: none;
  }
}