nav-bar-bottom {
  transition: transform 1.5s ease .2s;
  will-change: transform;


  &.attached {
    nav-bar-body {
    }

    //offset from iphoneX unsafe area
    .navBarBtnContent {
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    }
  }

  &.detached {
  }


}