$backColor: neutral(10);

radial-fade-dramatic-view {

  .backFill { opacity: 0; background-color: $backColor; }
  .radialGradient {
    opacity: 0;
    transform: scale(1);
    background: radial-gradient(
      ellipse at center,
      $backColor 10%,
      transparentize($backColor, 1) 70%
    );
  }
  .contentViewport { opacity:0; transform: translateY(7%) }

  .theNavBarForRadialDramatic { opacity: 0 }

  &.ENTERING {
    .backFill { opacity: 1; transition: opacity .3s ease .7s }
    .radialGradient {
      opacity: 1;
      transform: scale(6);
      transition:
        opacity .215s linear,
        transform .7s ease .12s;
    }
    .contentViewport { opacity:1; transform: translateY(0%); transition: all .4s ease .3s }
    .theNavBarForRadialDramatic { opacity: 1; transition: opacity .4s ease .5s }
  }

  &.EXITING {
    transition-duration: .8s;
    pointer-events: none;

    .backFill { opacity: 0; transition: opacity .2s linear }
    .radialGradient {
      opacity: 0;
      transform: scale(1);
      transition:
        opacity .4s linear .3s,
        transform .7s ease-in;
    }
    .contentViewport { transform: translateY(-10%); transition: all .3s ease-in }
    .theNavBarForRadialDramatic { transition: opacity .4s ease }

  }


  .switchInfoToolTip {
    &::before {
      content: '';
      position: absolute;
      left: len(2);
      bottom: calc(100% + 1px);
      border-left: len(1.7) solid transparent;
      border-right: len(1.7) solid transparent;
      border-bottom: len(1) solid transparentize(secondary(20), .8);
      filter: blur(.4px);
    }
    &::after {
      content: '';
      position: absolute;
      left: len(2);
      bottom: 100%;
      border-left: len(1.7) solid transparent;
      border-right: len(1.7) solid transparent;
      border-bottom: len(1) solid white;
    }
  }
}