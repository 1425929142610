$drawerEase: cubic-bezier(0.685, 0.970, 0.130, 1);
$boingEase: cubic-bezier(0.285, 1.595, 0.295, 0.940);
$slowFadeAway: cubic-bezier(0.36, 0.15, 0, 1);

boing-dramatic-view {

  .background { opacity: 0 }
  .hilight { opacity: 0 }
  .contentViewport { transform: translateY(100%); perspective: 500px }
  .headWrap { transform: rotateX(90deg); transform-origin: bottom }
  .closeBtnWrap { opacity: 0 }

  &.ENTERING {
    .background { opacity: 1; transition: opacity 1.5s ease }
    .hilight { opacity: .4; transition: opacity 3s 1s }
    .contentViewport { transform: translateY(0%); transition: transform 1s $drawerEase }
    .headWrap { transform: rotateX(0deg); transition: transform 1.2s $boingEase .43s }
    .closeBtnWrap { opacity: 1; transition: opacity 1s }
  }
  &.EXITING {
    transition-duration: 4s;

    .background { transition: opacity 3.4s $slowFadeAway }
    .hilight { transition-duration: 1s }
    .headWrap { transition: transform .3s, opacity .04s linear .1s; opacity: 0; }
    .contentViewport { transition: transform .3s ease-in .2s }
    .closeBtnWrap { transition-duration: .2s }
  }

  ////////// PRIMARY STYLES /////////////////////////////
  .primaryHilight {
    background: radial-gradient(
      ellipse at center,
      rgba(255,255,255,0.83) 0%,
      rgba(255,255,255,0) 100%
    )
  }


  .addJobWizardBackground {
    .successOverlay {
      transition: opacity 2.4s cubic-bezier(0.06, 0.37, 0.39, 1) 0.2s;
    }
  }
}