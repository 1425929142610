$spacing: 5%;

switch-old {
  &.small {
    .inputContainer {
      height: len(2);
      width: len(3.5);
    }
    .label {
      //@extend .fontSize-1;
    }

    .handle {
      height: len(1.7);
      width: len(1.7);
    }
    &.checked {
      .handle {
        transform: translate(85%, -50%);
      }
    }
  }

  &.normal {
    .inputContainer {
      height: len(3);
      width: len(5);
    }
    .handle {
      height: len(2.7);
      width: len(2.7);
    }
    &.checked {
      .handle {
        transform: translate(70%, -50%);
      }
    }
  }

  &.checked {
    .handle {
      box-shadow: 0 0 0 len(5) $clBrandSecondary;
    }
  }
  &:not(.checked) {
    &.secondaryTheme {
      .handle {
        box-shadow: 0 0 0 len(5) neutral(0);
      }
    }
    &.secondaryDarkTheme {
      .handle {
        box-shadow: 0 0 0 len(5) secondary(20);
      }
    }
  }

  .handle {
    left: $spacing;

    transition: .4s $ease;
    transition-property: box-shadow, transform;
  }

}
