levitating-btn {

  $bevelColor: rgba(0, 0, 0, .2);
  .bevelItem { background-color: $bevelColor }

  @mixin theme($color) {
    $bodyColor: $color;
    $hilightColor: lighten(adjust-hue(saturate($bodyColor, 20%), 2deg),8%);
    $shadeColor: transparentize($bodyColor, .76);
    $clickHilightColor: saturate($bodyColor, 30%);
    .body { background-color: $bodyColor }
    .theHilight { background: radial-gradient(ellipse at center, $hilightColor 0%, transparentize($hilightColor, 1) 50%) }
    .shade { box-shadow: 0 0 len(1) len(1) $shadeColor; background-color: $shadeColor }
    .clickHilight { background: radial-gradient(ellipse at center, transparentize($clickHilightColor, .6) 0%, transparentize($clickHilightColor, 1) 50%); }
  }

  &.primary { @include theme(primary(10)) }
  &.secondary { @include theme(secondary(20)) }
  &.neutral { @include theme(secondary(20)) }

  &:hover {
    .hilight {
      opacity: 1;
      transition-duration: .2s;
    }
    .clickHilight {
      opacity: .5;
    }
  }
  &:active {
    .backEffects .clickHilight {
      opacity: 1;
      transition-duration: .2s;
    }
  }


  .hotspot {
    $horizontalSpace: 25%;
    $verticalSpace: 50%;
    position: absolute;
    z-index: 10;

    &:active {
      ~ .graphics .body, ~ .graphics .body .bevelItem, ~ .graphics .body .labelText  {
        transition-duration: .1s
      }
      ~ .graphics .body .labelText {
        transform: translateZ(10px);
      }
    }

    &.top {
      top: 0;
      right: $horizontalSpace;
      left: $horizontalSpace;
      height: $verticalSpace;

      &:active ~ .graphics .body {
        transform: rotateX(20deg);
        transform-origin: 50% 80%;

        .bevelItemBottom {
          transform: translateY(-20%);
        }
      }
    }
    &.bottom {
      bottom: 0;
      right: $horizontalSpace;
      left: $horizontalSpace;
      height: $verticalSpace;

      &:active ~ .graphics .body {
        transform: rotateX(-20deg);
        transform-origin: 50% 20%;

        .bevelItemBottom {
          transform: translateY(30%);
        }
        .bevelItemTop {
          transform: translateY(38%);
        }
      }
    }
    &.right {
      right: 0;
      top: 0;
      bottom: 0;
      width: $horizontalSpace;

      &:active ~ .graphics .body {
        transform: rotateY(20deg);
        transform-origin: 40% 50%;

        .bevelItemLeft {
          transform: translateX(17%);
        }

        .bevelItemBottom {
          transform: rotateZ(.6deg);
          transform-origin: 30% top;
        }
      }
    }
    &.left {
      left: 0;
      top: 0;
      bottom: 0;
      width: $horizontalSpace;

      &:active ~ .graphics .body {
        transform: rotateY(-15deg);
        transform-origin: 60% 50%;

        .bevelItemRight {
          transform: translateX(-17%);
        }


        .bevelItemBottom {
          transform: rotateZ(-.6deg);
          transform-origin: 70% top;
        }
      }
    }
  }


  .bodyViewport {
    perspective: 800px;

    .body {
      transition: all .4s;
      transform-style: preserve-3d;

      .labelText {
        transform-style: preserve-3d;
        transform: translateZ(0.02px);
        transition: transform .4s;
      }
      .bodyEffects {
        transform: translateZ(0.01px);

        .bevelItem {
          transition: all .4s;

          &.bevelItemTop   { top:   -12%; height: 12% }
          &.bevelItemRight { right: -10%; width:  10% }
          &.bevelItemBottom{ bottom: -7%; height: 12% }
          &.bevelItemLeft  { left:  -10%; width:  10% }
        }

        .hilight {
          width: 150%;
          transition: all .4s ease-out;

          .theHilight {
            transform: translate(-50%, -50%);

            &::before {
              content: "";
              display: block;
              padding-top: 100%;
            }
          }

        }
      }
    }
  }
  .backEffects {

    .shade {
      top: 30%;
      right: 16%;
      left: 16%;
      bottom: -6%;
      border-radius: 1px;
    }
    .clickHilight {
      width: 300%;
      transition: all 1.8s;
    }

    .aRipple {
      width: 200%;
      animation: rippleAnim 2.5s cubic-bezier(0, 1.1, 0.24, 0.5) 0s 1 normal forwards;
    }
  }
}



@keyframes rippleAnim {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}
