drawer-dramatic-view {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: calc(100% - #{lenRound(7)});
  transform: translate3d(0, 100%, 0);
  transition: transform 1.4s cubic-bezier(0.1, 1.3, 0, 1);
  will-change: transform;

  &.ENTERING {
    transform: translate3d(0, 0%, 0);
  }
  &.EXITING {
    transform: translate3d(0, 110%, 0) !important;
    transition-duration: .3s;
    transition-timing-function: ease-in;
    pointer-events: none;
  }

  .exitClickOverlay {
    bottom: 100%;
    height: 100vh;
  }
  .backgroundWrap {
    bottom: -10%;
    box-shadow: 0 -7px 11px 4px rgba(0, 0, 0, 0.1);
  }
}