faq-items {
  faq-item {
    .iconArrow, .answer {
    }
    .answer {
      max-height: 0;
      transform: rotateX(-60deg) scale(0.9);
      transition-property: transform, opacity;
      transition-timing-function: $ease;
      transition-duration: .8s;
      opacity: 0;
      pointer-events: none;
    }
    .iconArrow {
      transition-duration: .6s;
    }

    &.opened {
      .answer {
        transform: rotateX(0) scale(1);
        max-height: 100%;
        opacity: 1;
      }

      .iconArrow {
        transform: rotate(90deg);
      }
    }
    &:nth-of-type(2n) {
      background: white;
    }
    &:nth-of-type(2n+1) {
      background: neutral(10);
    }
  }

}