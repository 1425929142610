customized-vertical-slide-select {
  &.snapLeft {
    .hilightOverlay {transform-origin: left}
    .backgroundLayer {transform-origin: left}
  }
  &.snapRight {
    .hilightOverlay {transform-origin: right}
    .backgroundLayer {transform-origin: right}
  }
  .hilightOverlay {
    transition:
      opacity .6s cubic-bezier(.31, .2, 0, 1),
      transform .6s cubic-bezier(.31, .2, 0, 1);
    transform: scaleX(.8);
  }
  .backgroundLayer {
    transform: rotateY(90deg);
  }
  & > div[selected-item] {
    .hilightOverlay {
      opacity: 1;
      transform: scaleX(1);
    }
    .labelRegular {
      opacity: 0;
    }
  }
}
