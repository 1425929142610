elm-framework-tooltip {
  .toolTipContainer {
    pointer-events: none;
    animation: delay-tooltip-pointer-events .7s linear;
  }
  .theTooltip {
    transition:
      transform 1.4s cubic-bezier(0.9, 0, 0.7, 0.83),
      opacity .9s .5s ease-out;
    opacity: 0;
    transform: translateY(20%) scaleY(1.3);
    transform-origin: top;
  }
  &:hover {
    .toolTipContainer {
      pointer-events: all;
      animation: none;
    }

    .theTooltip {
      opacity: 1;
      transform: translateY(0%);
      transition:
        transform 1.5s cubic-bezier(0, 0.28, 0.01, 0.99),
        opacity .4s ease-out;
    }
  }
}

@keyframes delay-tooltip-pointer-events {
  0% { pointer-events: all; }
  100% { pointer-events: all; }
}