.slideSelectHorizontal {
  padding-left: 50%;
  padding-right: 50%;
  scroll-snap-type: mandatory;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;

  & > div {
    scroll-snap-align: center;
  }
}