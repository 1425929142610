.slideSelectVertical {
  padding-top: 50%;
  padding-bottom: 50%;
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;

  & > div {
    scroll-snap-align: center;
  }
}