list-dramatic-view {
  $children: 10;
  $delay: 80ms;

  .listItemWrap { perspective: 830px }

  &.forwardAnimationDirection {

    @for $i from 1 through $children {
      .listItemWrap:nth-child(#{$i}) > .listItemManipulable {
        opacity: 0;
        transform: translate3d(0,4rem,-4rem) rotateX(-40deg);
        transform-style: preserve-3d;
        transition: all .8s ease $i * $delay;
      }
    }

    &.ENTERING {
      @for $i from 1 through $children {
        .listItemWrap:nth-child(#{$i}) > .listItemManipulable {
          opacity: 1;
          transform: none;
        }
      }
    }
    &.EXITING {
      @for $i from 1 through $children {
        .listItemWrap:nth-child(#{$i}) > .listItemManipulable {
          transition: all .4s ease $i * 30ms;
        }
      }

      transition-duration: $children * 30ms + .4s;
    }
  }

  &.backwardAnimationDirection {

    @for $i from 1 through $children {
      .listItemWrap:nth-last-child(#{$i}) > .listItemManipulable {
        opacity: 0;
        transform: translate3d(0,4rem,-4rem) rotateX(-40deg);
        transform-style: preserve-3d;
        transition: all .8s ease $i * $delay;
      }
    }

    &.ENTERING {
      @for $i from 1 through $children {
        .listItemWrap:nth-last-child(#{$i}) > .listItemManipulable {
          opacity: 1;
          transform: none;
        }
      }
    }
    &.EXITING {
      @for $i from 1 through $children {
        .listItemWrap:nth-last-child(#{$i}) > .listItemManipulable {
          transition: all .4s ease $i * 30ms;
        }
      }

      transition-duration: $children * 30ms + .4s;
    }
  }


}