
player-element {
  .bigBtn {
    .bigBtnColorOverlay {
      transition: all .8s cubic-bezier(0, 0.9, 0, 1) .1s;

      &.moveAwayRight, &.moveAwayLeft {
        transition: all .3s ease-out 0s;
      }
    }

    .btnIcon {
      transition: transform 2s cubic-bezier(0.15, 0.58, 0.25, 0.99),
      opacity .5s ease;
    }

    .scootOverToLeft {
      transform: translateX(len(-0.5));
    }

    .scootOverToRight {
      transform: translateX(len(0.5));
    }

    .scootOverMoreToLeft {
      transform: translateX(len(-1.5));
    }

    .scootOverMoreToRight {
      transform: translateX(len(1.5));
    }
  }
  .watchFaceDrama {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black);

  }
  .watchFace { //todo: this is a quick and dirty solution
    $distance: 100%;

    &[ef-drama] {
      transition: transform 1.1s cubic-bezier(.31, .2, 0, 1),
      opacity .3s linear;
    }

    &[ef-drama^=leave] {
      pointer-events: none;
    }

    &[ef-drama='enter-from-top-start'], &[ef-drama='leave-from-bottom'] { //adds `-start`
      opacity: 0;
      transform: translateY(-$distance);
    }

    &[ef-drama='enter-from-top'], &[ef-drama='enter-from-bottom'] {
      opacity: 1;
      transform: translateY(0);
    }

    &[ef-drama='leave-from-top'], &[ef-drama='enter-from-bottom-start'] {
      opacity: 0;
      transform: translateY($distance);
    }

    &[ef-drama='enter-from-left-start'], &[ef-drama='leave-from-right'] { //adds `-start`
      opacity: 0;
      transform: translateY(-$distance);
    }

    &[ef-drama='enter-from-left'], &[ef-drama='enter-from-right'] {
      opacity: 1;
      transform: translateY(0);
    }

    &[ef-drama='leave-from-left'], &[ef-drama='enter-from-right-start'] {
      opacity: 0;
      transform: translateY($distance);
    }


  }
}