* {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  overflow-anchor: none;
}

html {
  height: 100%;
  font-family: $fontFamily;
  color: black;
  overflow: hidden;
  background-color: secondary(10);
  -webkit-text-size-adjust: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1;

  font-size: $unitSize1 * 1px;
  @media screen and (min-width: 340px) {
    font-size: $unitSize2 * 1px;
  }
  @media screen and (min-width: 1200px) and (min-height: 600px) {
    font-size: $unitSize3 * 1px;
  }
  @media screen and (min-width: 1800px) and (min-height: 800px) {
    font-size: $unitSize4 * 1px;
  }
}

body {
  height: 100%;
  cursor: default;
  overflow: hidden;
  touch-action: manipulation; // prevents double-tap to zoom
}

*[contenteditable] {
  -webkit-user-select: auto !important;
  -webkit-touch-callout: default !important;
}

[contenteditable] {
  cursor: text;
}

textarea {
  resize: vertical;
  -webkit-appearance: none;
}

input:not([type=checkbox]):not([type=radio]) {
  -webkit-appearance: none;
}

input {
  background: transparent;
  border: none;
  border-radius: 0; //safari
}
input[type='file']{
  cursor: pointer;
  font-size: 0;
}

a {
  text-decoration: none;
}

img {
  -webkit-user-drag: none;
  pointer-events: none;
}


#fb-root {
  display: none;
}
