$movement: cubic-bezier(0.275, 0.025, 0.000, 1.000);

radio-slider {
  .selectedItem {
    transition: transform .9s $movement;

    .selectedItems {
      transition: transform .9s ease;
    }
  }
}


duple-text-slider {
  .itemTrans {
    transition: .9s $movement;

    .selectedItems {
      transition: transform .9s ease;
    }
  }
}