bottom-menu {
  &.opened {
    .menuItemsWrap {
      transform: translateY(-100%);
    }
  }
  .menuItemsWrap {
    transition: transform .5s;
    transform: translateY(0%);
  }
}

bottom-nav {
  will-change: transform;
  transform: tranlsateZ(0);

  .bottomMenuWrap {
    transition: transform .5s;
  }

  .btnSlot {
    transition: transform .8s;

    &:not(:last-of-type) {
      border-right: 1px solid transparentize(neutral(0), .92);
    }
    &::after {
      content: "";
      position: absolute;
      left: 0; right: 0; bottom: 0;
      height: len(.5);
    }


    .aBtn {
      transform: translateY(200%);
      transition: transform .9s $ease;
      &.shown { transform: translateY(0%) }


      &.Close {
        @extend .gray20Back;
        .icon, .text {
          @extend .secondary0;
        }
      }
    }
  }

  &.secondaryStyle {
    .btnSlot {
      @extend .secondary0Back;

      &:not(last-chield)::after {
        background: transparentize(black, .8);
      }
      &:last-child {
        &::after {
          background: transparentize(black, .925);
        }
      }

      &:last-child {
        @extend .primary10Back;
        .text {
          @extend .white;
        }
        .icon {
          @extend .white;
        }
      }
    }

    .aBtn {
      .text {
        @extend .gray20;
      }
      .icon {
        @extend .secondary20;
      }
    }

  }


  &.transparentStyle {
    box-shadow: 0 0 len(.3) len(.1) transparentize(secondary(20), .65);

    .btnSlot {
      &::after {
        background: transparentize(black, .9);
      }
    }

    .aBtn {
      .text, .icon {
        @extend .white;
      }
    }
  }
}