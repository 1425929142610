h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-size: len(1);
  @include fontWeight(400);
}

.boxSizingContent { box-sizing: content-box }
.boxSizingBorder { box-sizing: border-box !important; }


.block {display: block}
.inlineBlock {display: inline-block}
.inlineFlex {display: inline-flex}
.inline {display: inline}

.relative {position: relative}
.absolute {position: absolute}

.left {text-align: left}
.center {text-align: center}
.right {text-align: right}
.justify { text-align: justify }
.justifyOneLine { text-align: justify; text-align-last: justify; }
.justifyCenterLine { text-align: justify; text-align-last: center; }
.middle { vertical-align: middle }
.baseline { vertical-align: baseline }
.verticalAlignTop { vertical-align: top }

.floatRight {float: right}
.floatLeft {float: left}

.hidden {display: none !important;}


.absoluteSpread {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.noOverflow { overflow: hidden;  }
.overflow { overflow: auto; }
.noOverflowY {overflow-y: hidden}

.noResize {resize: none}

.white { color: white; fill: white; }
.black { color: black !important; fill: black; }
.placeholderBlack {
  &::placeholder {
    color: black;
  }
}
.placeholderWhite {
  &::placeholder {
    color: white;
  }
}
.overlay {
  background-color: transparentize(#193341, 0.6);
}

.whiteBack { background-color: white }
.blackBack { background-color: black }
.transparentBack { background-color: transparent }
.transparent { color: transparent }
.capitalize { text-transform: capitalize}

@each $colorKey in map-keys($primaryPalette) {
  .primary#{$colorKey} {color: primary($colorKey)}
  .primary#{$colorKey}Back {background-color: primary($colorKey); }
  .primary#{$colorKey}Fill {fill: primary($colorKey);}
  .primary#{$colorKey}Border {border-color: primary($colorKey)}
  .placeholderPrimary#{$colorKey} {
    &::placeholder {
      color: primary($colorKey);
    }
  }
}
@each $colorKey in map-keys($secondaryPalette) {
  .secondary#{$colorKey} {color: secondary($colorKey)}
  .secondary#{$colorKey}Back {background-color:secondary($colorKey)}
  .secondary#{$colorKey}Fill {fill: secondary($colorKey)}
  .secondary#{$colorKey}Border {border-color: secondary($colorKey)}
  .placeholderSecondary#{$colorKey} {
    &::placeholder {
      color: secondary($colorKey);
    }
  }
}
@each $colorKey in map-keys($neutralPalette) {
  .gray#{$colorKey} {color: neutral($colorKey)}
  .gray#{$colorKey}Back {background-color: neutral($colorKey)}
  .gray#{$colorKey}Fill {fill: neutral($colorKey)}
  .gray#{$colorKey}Border {border-color: neutral($colorKey);}
  .placeholderGray#{$colorKey} {
    &::placeholder {
      color: neutral($colorKey);
    }
  }
}
@each $gradKey in map_keys($gradients) {
  .gradient#{$gradKey} {color: gradients($gradKey)}
  .gradient#{$gradKey}Back {background: gradients($gradKey) !important}
  .gradient#{$gradKey}Fill {fill: gradients($gradKey)}
  .gradient#{$gradKey}Border {border-image: gradients($gradKey)}
}

@each $number in 0 1 2 3 4 5 6 7 8 9 10 {
  .z#{$number} {z-index: $number}
  .o#{$number} { opacity: $number/10 }
  .o0#{$number} { opacity: $number/100 }
  .vh#{$number} { height: $number*10vh }
  .flex#{$number} { flex: $number }
  .gap#{$number} {
    margin: len(-$number/2);
    & > * {margin: len($number/2)}
  }
  .gapRow#{$number} > * + * { margin-left: len($number) }
  .gapColumn#{$number} > * + * { margin-top: len($number) }

  .transparentizedWhite#{$number}Back { background-color: transparentize(white, $number / 10) }
  .transparentizedWhite#{$number} { color: transparentize(white, $number / 10) }
  .borderWidth#{$number} {border-width: if($number == 0, 0, len(0.1 * $number)) }
  .borderWidthTop#{$number} {border-top-width: if($number == 0, 0, len(0.1 * $number)) }
  .borderWidthRight#{$number} {border-right-width: if($number == 0, 0, len(0.1 * $number)) }
  .borderWidthBottom#{$number} {border-bottom-width: if($number == 0, 0, len(0.1 * $number)) }
  .borderWidthLeft#{$number} {border-left-width: if($number == 0, 0, len(0.1 * $number)) }
  .height#{$number}tenth {height: if($number == 0, 0, len(0.1 * $number))}
}
.z-1 { z-index: -1 }

@each $number in 0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 26 28 29 30 32 33 34 35 36 38 39 40 41 42 43 46 48 50 58 60 62 65 70 97 {
  .w#{$number} { width: lenRound($number); min-width: lenRound($number) }
  .h#{$number} { height: lenRound($number); min-height: lenRound($number) }
  .maxW#{$number} { max-width: lenRound($number) }
  .maxH#{$number} { max-height: lenRound($number) }
  .minW#{$number} { min-width: lenRound($number) }
  .minH#{$number} { min-height: lenRound($number) }
  .pad#{$number} { padding: lenRound($number) }
  .lineH#{$number} { line-height: lenRound($number) }
  html .padTop#{$number} { padding-top: lenRound($number) }
  html .padRight#{$number} { padding-right: lenRound($number) }
  html .padBottom#{$number} { padding-bottom: lenRound($number) }
  html .padLeft#{$number} { padding-left: lenRound($number) }

  //halves
  .w#{$number}h { width: lenRound($number + .5); min-width: lenRound($number + .5) }
  .h#{$number}h { height: lenRound($number + .5); min-height: lenRound($number + .5) }
  .minW#{$number}h { min-width: lenRound($number + .5) }
  .minH#{$number}h { min-height: lenRound($number + .5) }
  .pad#{$number}h { padding: lenRound($number + .5) }
  html .padTop#{$number}h { padding-top: lenRound($number + .5) }
  html .padRight#{$number}h { padding-right: lenRound($number + .5) }
  html .padBottom#{$number}h { padding-bottom: lenRound($number + .5) }
  html .padLeft#{$number}h { padding-left: lenRound($number + .5) }
  .lineH#{$number}h { line-height: lenRound($number + .5) }

  //quarters
  .w#{$number}q { width: lenRound($number + .25); min-width: lenRound($number + .25) }
  .h#{$number}q { height: lenRound($number + .25); min-height: lenRound($number + .25) }
  .minW#{$number}q { min-width: lenRound($number + .25) }
  .minH#{$number}q { min-height: lenRound($number + .25) }
  .pad#{$number}q { padding: lenRound($number + .25) }
  html .padTop#{$number}q { padding-top: lenRound($number + .25) }
  html .padRight#{$number}q { padding-right: lenRound($number + .25) }
  html .padBottom#{$number}q { padding-bottom: lenRound($number + .25) }
  html .padLeft#{$number}q { padding-left: lenRound($number + .25) }
  .lineH#{$number}q { line-height: lenRound($number + .25) }
}
.noPad { padding: 0; }
.noMar { margin: 0; }
.marTop-halfR {
  &::before {
    content: "";
    display: block;
    margin-top: -50%;
  }
}

@each $number in -15 -13 -11 -10 -9 -8 -7 -6 -5 -4 -3 -2 -1 0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 25 29{
  .mar#{$number} { margin: lenRound($number) }
  html .marTop#{$number} { margin-top: lenRound($number) }
  html .marRight#{$number} { margin-right: lenRound($number) }
  html .marBottom#{$number} { margin-bottom: lenRound($number) }
  html .marLeft#{$number} { margin-left: lenRound($number) }
  // 'h' means 'and a half'
  .mar#{$number}h { margin: lenRound($number + sign($number, .5)) }
  html .marTop#{$number}h { margin-top: lenRound($number + sign($number, .5)) }
  html .marRight#{$number}h { margin-right: lenRound($number + sign($number, .5)) }
  html .marBottom#{$number}h { margin-bottom: lenRound($number + sign($number, .5)) }
  html .marLeft#{$number}h { margin-left: lenRound($number + sign($number, .5)) }
  @if $number == 0 {
    html .marTop-0h { margin-top: lenRound(-.5) }
    html .marRight-0h { margin-right: lenRound(-.5) }
    html .marBottom-0h { margin-bottom: lenRound(-.5) }
    html .marLeft-0h { margin-left: lenRound(-.5) }
    html .left-0h { left: lenRound(-.5) }
    html .right-0h { right: lenRound(-.5) }
    html .top-0h { top: lenRound(-.5) }
    html .bottom-0h { bottom: lenRound(-.5) }
  }
  .left#{$number} { left: len($number) }
  .right#{$number} { right: len($number) }
  .top#{$number} { top: len($number) }
  .bottom#{$number} { bottom: len($number) }

  .left#{$number}h { left: len($number + .5) }
  .right#{$number}h { right: len($number + .5) }
  .top#{$number}h { top: len($number + .5) }
  .bottom#{$number}h { bottom: len($number + .5) }
}

.noFontSize { font-size: 0 }
.circleShadow {
  box-shadow: 0 0 0 lenRound(1) white;
}

.noShade {box-shadow: none}
.italic { font-style: italic; }
.normal {font-style: normal}

@each $key in map-keys($fontSizes) {
  .fontSize#{$key} { font-size: fontSize($key) }
  .placeholderFontSize#{$key} {
    &::placeholder {
      font-size: fontSize($key);
    }
  }
  .fontSizeBaseLine#{$key} {
    position: relative;
    top: 1.1 - fontSize($key);
    font-size: fontSize($key);
  }
}

@each $weight in $fontWeights {
  @if($weight == 300) {
    .weight#{$weight}i {
      @include fontWeight($weight);
      font-style: italic;
    }
    .placeholderWeight#{$weight}i {
      &::placeholder {
        @include fontWeight($weight);
        font-style: italic;
      }
    }
  }

  .weight#{$weight} { @include fontWeight($weight) }
  .placeholderWeight#{$weight} {
    &::placeholder {
      @include fontWeight($weight);
    }
  }
}
@each $key in map-keys($fontSizes) {
  .fontSize#{$key} { font-size: fontSize($key) }
  .placeholderFontSize#{$key} {
    &::placeholder {
      font-size: fontSize($key);
    }
  }
  .fontSizeBaseLine#{$key} {
    position: relative;
    top: 1.1 - fontSize($key);
    font-size: fontSize($key);
  }
}
@each $key in map-keys($letterSpacings) {
  .ls#{$key} { letter-spacing: letterSpacing($key) }
}
@each $number in 0 1 2 3 4 5 6 7 8 {
  .fontSize#{$number}f { font-size: len($number) }
  .fontSize#{$number}hf { font-size: len($number + .5) }
}

@each $key in map-keys($borderRadius) {
  .radius#{$key} { @include borderRadius($key) }
  .radiusTop#{$key} { @include borderRadiusTopLeft($key); @include borderRadiusTopRight($key); }
  .radiusBottom#{$key} { @include borderRadiusBottomLeft($key); @include borderRadiusBottomRight($key); }
  .radiusRight#{$key} { @include borderRadiusBottomRight($key); @include borderRadiusTopRight($key); }
  .radiusLeft#{$key} { @include borderRadiusBottomLeft($key); @include borderRadiusTopLeft($key); }
  .radiusTopLeft#{$key} { @include borderRadiusTopLeft($key) }
  .radiusTopRight#{$key} { @include borderRadiusTopRight($key) }
  .radiusBottomLeft#{$key} { @include borderRadiusBottomLeft($key) }
  .radiusBottomRight#{$key} { @include borderRadiusBottomRight($key) }
}

.coverBack {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.containBack {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.superscript {
  vertical-align: super;
  font-size: .4em;
}
.borderRadiusBugFix {
  opacity: 0.99;
  overflow: hidden;
}

.maxRadius {border-radius: 1000px }
.maxRadiusLeft {border-top-left-radius: 1000px; border-bottom-left-radius: 1000px}
.maxRadiusRight {border-top-right-radius: 1000px; border-bottom-right-radius: 1000px}
.circle {border-radius: 50%}
.rotate90 { transform: rotate(90deg) }
.rotate-90 { transform: rotate(-90deg) }
.rotate180 { transform: rotate(180deg) }
.pointer { cursor: pointer }
.cursorNotAllowed { cursor: not-allowed }
.cursorInherit { cursor: inherit }
.marAuto { margin: auto }
.marTopAuto { margin-top: auto }
.marRightAuto { margin-right: auto }
.marBottomAuto { margin-bottom: auto }
.marLeftAuto { margin-left: auto }
.noBackfaceVisibility { backface-visibility: hidden }
.noBackground { background: none }
.visibilityHidden { visibility: hidden }

.scrollTarget {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}
.scrollTargetX {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.preventKineticScroll {
  -webkit-overflow-scrolling: auto;
}

.noScrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.smoothScroll {
  scroll-behavior: smooth;
}

.o0NoPointerEvents {
  opacity: 0;
  pointer-events: none;
}

.opacityTransition {
  transition: opacity .4s $ease;
}
.opacityTransitionSlow {
  transition: opacity 1.4s $ease;
}
.backgroundColorTransition {
  transition: background-color .4s $ease;
}
.baseTransition {
  transition: .4s $ease;
}
.transformTransition {
  transition: transform .4s;
}
.colorTransition {
  transition: color .4s $ease;
}
.allTransition {
  transition: all .4s;
}
.perspective {
  perspective: 1000px;
}

.takeHalf { width: 50% }
.takeMinHalf { min-width: 50% }
.takeQuarterWidth { width: 25% }
.takeMoreThenHalf {width: 58%}

.fixedSpread {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.absoluteWindowSpread {
  position: absolute;
  width: 100vw;
  height: 100vh;
}
.backgroundPositionYCenter {
  background-position-y: 50%;
}
.backgroundPositionYBottom {
  background-position-y: 100%;
}

// FLEXING

lay-flex-column, .flexColumn, lay-inlineFlex-column, .inlineFlexColumn {
  display: inline-flex;
  flex-direction: column;
}
lay-flex-column, .flexColumn {
  display: flex;
}
lay-inlineFlex-column, .inlineFlexColumn, lay-inlineFlex-row, .inlineFlexRow {
  display: inline-flex;
}
lay-flex-row, .flexRow {
  display: flex;
  flex-direction: row;
}
.inlineFlexRow {
  display: inline-flex;
  flex-direction: row;
}
.flexNoWrap {
  flex-wrap: nowrap;
}
.flexWrap {
  flex-wrap: wrap;
}
.noFlexShrink { flex-shrink: 0 }
.overflowXAuto { overflow-x: auto}
.overflowXScroll { overflow-x: scroll}
.whiteSpaceNoWrap { white-space: nowrap }
.whiteSpaceNormal {white-space: normal}
.whiteSpacePreLine {white-space: pre-line}
.overflowScrollingTouch {-webkit-overflow-scrolling: touch}
.basis0 {flex-basis: 0}

.spaceBetween { justify-content: space-between }
.spaceEvenly { justify-content: space-evenly }
.spaceAround { justify-content: space-around }
.stretchContent { justify-content: stretch }
.spaceAroundEqualEdges {
  justify-content: space-between;
  &::before, &::after {
    content: "";
  }
}

.justifyStart { justify-content: flex-start }
.justifyEnd { justify-content: flex-end }
.justifyCenter { justify-content: center }

.flexAlignCenter { align-items: center }
.flexAlignStart { align-items: flex-start }
.flexAlignEnd { align-items: flex-end }
.flexAlignStretch { align-items: stretch }
.flexAlignBaseline { align-items: baseline }
.textOverflowEllipsis { text-overflow: ellipsis }
.ellipsis { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.flexAlignSelfFlexStart { align-self: flex-start }
.flexAlignSelfFlexEnd { align-self: flex-end }
.flexAlignSelfCenter { align-self: center }
.noListStyle { list-style: none }
.flexCenter { @extend .flexRow, .justifyCenter, .flexAlignCenter }
.inlineFlexCenter { @extend .inlineFlexRow, .justifyCenter, .flexAlignCenter }
.flexCenterColumn { @extend .flexColumn, .justifyCenter, .flexAlignCenter }
.inlineFlexCenterColumn { @extend .inlineFlexColumn, .justifyCenter, .flexAlignCenter }

.moveAwayRight { transform: translateX(100%); }
.moveAwayLeft { transform: translateX(-100%); }
.moveAwayBottom { transform: translateY(100%); }
.moveAwayTop { transform: translateY(-100%); }
.absoluteCenterX {
  position: absolute;
  @extend  .centerX
}
.centerX {
  left: 50%;
  transform: translateX(-50%);
}
.centerY {
  top: 50%;
  transform: translateY(-50%);
}
.absoluteCenterY {
  position: absolute;
  @extend .centerY
}
.absoluteCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.absoluteCenterBottom {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);
}
.promoteLayerGpuLayer {
  transform: translateZ(0.1px);
}
.clearfix:after {
  content:"";
  display:block;
  clear:both;
}
.fixed {position: fixed}
.sticky {position: sticky; position: -webkit-sticky}
.fullWidth { width: 100% }
.fullHeight { height: 100% }
.fullWindowWidth { width: 100vw }
.fullWindowHeight { height: 100vh }
.fullMinWidth { min-width: 100% }
.fullMinHeight { min-height: 100% }
.fullMaxWidth { max-width: 100% }
.fullMaxHeight { max-height: 100% }
.hairlineWidth { width: len(0.083333333) }
.hairlineHeight { height: len(0.083333333) }
.fullTop { top: 100% }
.fullRight { right: 100% }
.fullBottom { bottom: 100% }
.fullLeft { left: 100% }
.halfTop { top: 50% }
.halfRight { right: 50% }
.halfBottom { bottom: 50% }
.halfLeft { left: 50% }
.inheritWidth {width: inherit}
.inheritHeight {height: inherit}
.inheritTransition { transition: inherit }
.noSideMargin {
  > :first-child {
    margin-left: 0;
  }
  > :last-child {
    margin-right: 0;
  }
}
.noSidePad {
  > :first-child {
    padding-left: 0;
  }
  > :last-child {
    padding-right: 0;
  }
}
.lastOfTypeNoPadBottom {
  &:last-of-type {
    padding-bottom: 0;
  }
}
.lastOfTypeNoMarBottom {
  &:last-of-type {
    margin-bottom: 0;
  }
}
// LAYOUT - columns
lay-row, .row {
  display: block;
  @extend .padRight0h;
  @extend .padLeft0h;
  @extend .clearfix;
}
@each $number in 1 2 3 4 5 6 7 8 9 10 {
  lay-col#{$number}, .col#{$number} {
    display: block;
    float: left;
    width: 100% / $number;
    @extend .padRight0h;
    @extend .padLeft0h;
    @extend .padBottom1;
  }
}

@each $number in 1 2 3 4 5 6 {
  .flexItemsPerRow#{$number} {
    flex-wrap: wrap;
    > * {
      flex-basis: #{round(100%/$number)};

      &:nth-last-child(-n + #{$number}) {
        //margin-bottom: 0;
      }
    }
  }
}

.noLeftRadius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.noRightRadius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.inheritRadius {
  border-radius: inherit;
}
.inheritLeftRadius {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.inheritRightRadius {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.inheritTopRadius {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.inheritBottomRadius {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

@each $numberX in 0 1 2 {
  @each $numberY in 0 1 2 {
    .borderSpacing#{$numberX}#{$numberY} {
      border-spacing: lenRound($numberX) lenRound($numberY);
    }
  }
}
.wordBreakAll { word-break: break-all }
.breakWord { word-break: break-word }

.borderCollapseSeparate {border-collapse: separate}
.borderSolid {border-style: solid}
.borderDashed {border-style: dashed}

.noUserSelect {user-select: none}
.noBorder {border: none}
.noPointerEvents {pointer-events: none}
.yesPointerEvents {pointer-events: auto}
.noOutline {outline: none}
.noFontSize {font-size: 0}
.fixedTable {table-layout: fixed}

.red {color: $clRed}
.green {color: $clGreen}
.google {color: $clGoogle}
.facebook { color: $clFacebook }
.twitter { color: $clTwitter }
.linkedin { color: $clLinkedin }


.redBack {background-color: $clRed}
.greenBack {background-color: $clGreen}
.googleBack {background-color: $clGoogle}
.facebookBack { background-color: $clFacebook }
.twitterBack { background-color: $clTwitter }
.linkedinBack { background-color: $clLinkedin }

.transparentBorder { border-color: transparent }
.transparentBorderTop { border-top-color: transparent }
.transparentBorderRight { border-right-color: transparent }
.transparentBorderBottom { border-bottom-color: transparent }
.transparentBorderLeft { border-left-color: transparent }
.blackBorder {border-color: black; border-style: solid}
.redBorder {border-color: $clRed; border-style: solid}
.greenBorder {border-color: $clGreen; border-style: solid}
.googleBorder {border-color: $clGoogle; border-style: solid}
.facebookBorder { border-color: $clFacebook ; border-style: solid}
.twitterBorder { border-color: $clTwitter ; border-style: solid}
.linkedinBorder { border-color: $clLinkedin ; border-style: solid}

.placeholderRed {&::placeholder{color: $clRed} }
.placeholderGreen {&::placeholder{color: $clGreen} }
.placeholderGoogle {&::placeholder{color: $clGoogle} }
.placeholderFacebook {&::placeholder{color: $clFacebook} }
.placeholderTwitter {&::placeholder{color: $clTwitter} }

.square {
  &::after {
    content: "";
    display: block;
    padding-top: 100%;
  }
}


// Safe Areas
.topSafeDistance { top: env(safe-area-inset-top); top: constant(safe-area-inset-top); }
.rightSafeDistance { right: env(safe-area-inset-right); right: constant(safe-area-inset-right); }
.bottomSafeDistance { bottom: env(safe-area-inset-bottom); bottom: constant(safe-area-inset-bottom); }
.leftSafeDistance { left: env(safe-area-inset-left); left: constant(safe-area-inset-left); }
.padTopSafeDistance { padding-top: env(safe-area-inset-top); padding-top: constant(safe-area-inset-top); }
.padRightSafeDistance { padding-right: env(safe-area-inset-right); padding-right: constant(safe-area-inset-right); }
.padBottomSafeDistance { padding-bottom: env(safe-area-inset-bottom); padding-bottom: constant(safe-area-inset-bottom); }
.padLeftSafeDistance { padding-left: env(safe-area-inset-left); padding-left: constant(safe-area-inset-left); }

.monospaceFont {font-family: monospace; font-weight: bold }
.noInputStyles {border: none; background: transparent}

.underline {text-decoration: underline}

.lineHeightNormal {line-height: normal}


.minContentWidth { min-width: min-content; }
.maxWidthPhone {max-width: len($phoneWidthUnits)}
.maxWidthDesktop {max-width: len($desktopWidthUnits)}
.maxWidthMoreThanDesktop {max-width: len($biggerThanDesktopWidthUnits)}
.maxWidthBetween {max-width: len($betweenPhoneDesktopWidthUnits)}

.phoneWidthCenter {
  @extend .maxWidthPhone;
  @extend .marLeftAuto;
  @extend .marRightAuto;
}
.desktopWidthCenter {
  @extend .maxWidthDesktop;
  @extend .marLeftAuto;
  @extend .marRightAuto;
}
.moreThanDesktopWidth {
  @extend .maxWidthMoreThanDesktop;
  @extend .marLeftAuto;
  @extend .marRightAuto;
}
.betweenPhoneDesktopWidth {
  @extend .maxWidthBetween;
  @extend .marLeftAuto;
  @extend .marRightAuto;
}

.transformStyleFlat {
  transform-style: flat;
}
.transformStylePreserve3d {
  transform-style: preserve-3d;
}

.semiTransparent {
  opacity: .5;
}

.uppercase {text-transform: uppercase}

.slidesX {
  overflow: hidden;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  & > * {
    scroll-snap-align: center;
  }
}
.objectFitCoverCenter {
  object-fit: cover;
  object-position: center;
}

.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}

.notificationCircle {
  background: $notificationRed;
}

.addNotificationCircle {
  position:relative;
  &:after {
    position:absolute;
    right:-12px;
    top:0px;
    min-width:7px;
    min-height:7px;
    //padding:5px;
    background-color:$notificationRed;
    border-radius:20px;
    border:solid 1px $notificationRed;
    content: ""
  }
}