@mixin depths ($multiplier) {
  @each $number in 1 2 3 4 5 {
    .depth#{$number} {
      @if($multiplier > 0px) {
        transform: translateZ($number * $multiplier);
      } @else {
        transform: none; // fixes blurred state in safari
      }
      transition: transform 1s ease;
    }
  }
}

$ease: cubic-bezier(0.105, 0.935, 0.475, 1.000);

///////////////////////////////////////////////////////////////////////////////////////

projected-shadow-dramatic-view {
  .overlay, .projectedShadow, .contentWrap3dContext, .contentWrap {
    transition-property: all;
  }
  .closeBtn { opacity: 0 }
  .overlay { opacity: 0 }
  .windowViewport { perspective: 500px }
  .projectedShadow {
    top: 5%; right: 5%; bottom: 15%; left: 5%;
    opacity: 0;
    transform: rotateX(-30deg) translate3d(0, -200%, -30px);
  }
  .contentWrap3dContext {
    opacity: 0;
    perspective: 500px;
  }
  .contentWrap {
    transform: rotateX(-30deg);
    transform-style: preserve-3d;
    transform-origin: center 85%;
  }
  @include depths(30px);

///////////////////////////////////////////////////////////////////////////////////////

  &.ENTERING {
    .closeBtn {
      transition-duration: 2s;
      opacity: 1;
    }
    .overlay {
      transition-duration: 5s;
      opacity: .4
    }
    .projectedShadow {
      opacity: .4;
      transform: rotateX(0deg) translate3d(0, 20%, -30px);
      transition-duration: 2s;
      transition-timing-function: $ease;
    }
    .contentWrap3dContext {
      opacity: 1;
      transition: opacity .5s ease;
    }
    .contentWrap {
      transform: none;
      transition: transform 1.5s $ease;
    }
    @include depths(0px);
  }
  ///////////////////////////////////////////////////////////////////////////////////////

  &.EXITING {
    transition-duration: 3s;

    .closeBtn {
      transition-duration: .4s;
    }
    .overlay {
      transition-duration: 3s;
      opacity: 0
    }
    .projectedShadow {
      transition-duration: .4s;
      opacity: 0;
      transform: rotateX(30deg) translate3d(0, 80%, -50px);
    }
    .contentWrap3dContext {
      transition-duration: .4s;
    }
    .contentWrap {
      transition-duration: .4s;
      transform: rotateX(30deg)
    }
    @include depths(50px);

    pointer-events: none;
  }
}


//opacity flattening decision:
// https://bugs.chromium.org/p/chromium/issues/detail?id=646993#
// https://groups.google.com/a/chromium.org/forum/#!search/blink-dev$20opacity$20flattening/blink-dev/eBIp90_il1o/9q3M0ww2BgAJ
