date-overlay-view {
  .pullColumnRight { margin-right: -3px }
  .pullColumnLeft { margin-left: -3px }
  .dateItem {
    &::after {
      content: "";
      position: absolute;
      top: -60%;
      bottom: -60%;
      width: 3px;
      background: linear-gradient(
          transparentize(secondary(20), 1),
          transparentize(secondary(20), .5) 42%,
          transparentize(secondary(20), .2) 50%,
          transparentize(secondary(20), .5) 58%,
          transparentize(secondary(20), 1)
      );
      opacity: 0;
      transition: opacity .7s;
    }

    &::before {
      content: "";
      pointer-events: none;
      position: absolute;
      top: -90%;
      bottom: -90%;
      width: 90%;
      background: radial-gradient(
        #{transparentize(secondary(20), .8)},
        #{transparentize(secondary(20), 1)} 50%
      );
      opacity: 0;
      transition: opacity 1.4s;
    }


    &.left {
      &::after {left: 0}
      &::before {left: -50%}
      &.selected::after, &.selected::before {opacity: 1}
    }
    &.right {
      &::after {right: 0}
      &::before {right: -50%}
      &.selected::after, &.selected::before {opacity: 1}
    }
  }
}

.datePickerOverlayGradient {
  background: linear-gradient(
      transparentize(neutral(10), 0),
      transparentize(neutral(10), .3) 17%,
      transparentize(neutral(10), 1) 33%,
      transparentize(neutral(10), 1) 66%,
      transparentize(neutral(10), .3) 82%,
      transparentize(neutral(10), 0)
  );
}
