




@mixin zIndex ($items, $item) {
  z-index: index($items, $item);
}

@mixin setFont($size, $height) {
  font-size: $size * $unit;
  line-height: $height * $unit;
}


@mixin borderRadius($index) {
  @if(contains(map-keys($borderRadius), $index)) {
    border-radius: borderRadius($index);
  } @else {
    @error 'no such border radius';
  }
}
@mixin borderRadiusTopLeft($index) {
  @if(contains(map-keys($borderRadius), $index)) {
    border-top-left-radius: borderRadius($index);
  } @else {
    @error 'no such border radius';
  }
}

@mixin borderRadiusTopRight($index) {
  @if(contains(map-keys($borderRadius), $index)) {
    border-top-right-radius: borderRadius($index);
  } @else {
    @error 'no such border radius';
  }
}
@mixin borderRadiusBottomLeft($index) {
  @if(contains(map-keys($borderRadius), $index)) {
    border-bottom-left-radius: borderRadius($index);
  } @else {
    @error 'no such border radius';
  }
}
@mixin borderRadiusBottomRight($index) {
  @if(contains(map-keys($borderRadius), $index)) {
    border-bottom-right-radius: borderRadius($index);
  } @else {
    @error 'no such border radius';
  }
}

@mixin fontWeight($weight) {
  @if(contains($fontWeights, $weight)) {
    font-weight: $weight;
  } @else {
    @error 'no such weight';
  }
}


@function to-string($list, $glue: '', $is-nested: false) {
  $result: null;

  @for $i from 1 through length($list) {
    $e: nth($list, $i);

    @if type-of($e) == list {
      $result: $result#{to-string($e, $glue, true)};
    }

    @else {
      $result: if($i != length($list) or $is-nested, $result#{$e}#{$glue}, $result#{$e});
    }
  }

  @return $result;
}

@mixin transition ($props, $theRest...) {
  $transitions: ();
  @each $prop in $props {
    $transitions: append($transitions, to-string($prop $theRest, " "));
  }
  transition: to-string($transitions, ", ");
}
