customized-horizontal-slide-select {
  .hilightOverlay {
    transition:
      opacity .6s cubic-bezier(.31, .2, 0, 1),
      transform .6s cubic-bezier(.31, .2, 0, 1);
    transform: scaleY(.8);
  }
  .backgroundLayer {
    transform: rotateX(90deg);
  }
  & > div[selected-item] {
    .hilightOverlay {
      opacity: 1;
      transform: scaleY(1);
    }
    .labelRegular {
      opacity: 0;
    }
  }
}
